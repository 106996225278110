

 .nav1, .menu1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav1 {
    position: relative;
    /* background-color: #fff; */
    /* padding: 20px; */
    transition: 0.2s;
    /* border-radius: 50px; */
    overflow: hidden;
    /* box-shadow: 0 8px 15px rgba(0,0,0,.2); */
}

.menu1 {
    margin: 0;
    padding: 0;
    width: 0;
    overflow: hidden;
    transition: 0.5s;
}

.nav1 input:checked ~ .menu1 {
    width: 450px;
}

.menu1 li {
    list-style: none;
    margin: 0 7px;
    font-size: 15px;
}

.menu1 li a {
    text-decoration: none;
    color:white;
    text-transform: uppercase;
    /* font-weight: 600; */
    transition: 0.5s;
}

.menu1 li a:hover {
   
}

.nav1 input {
    width: 61px;
    /* height: 40px; */
    cursor: pointer;
    opacity: 0;
}

.nav1 span {
    position: absolute;
    left: 30px;
    width: 30px;
    height: 4px;
    border-radius: 50px;
    background-color:white;
    pointer-events: none;
    transition: 0.5s;
}

.nav1 input:checked ~ span {
    background: white;
}

.nav1 span:nth-child(2) {
    transform: translateY(-8px);
}

.nav1 input:checked ~ span:nth-child(2) {
    transform: translateY(0) rotate(-45deg);
}
.nav1 span:nth-child(3) {
    transform: translateY(8px);
}

.nav1 input:checked ~ span:nth-child(3) {
    transform: translateY(0) rotate(45deg);
}

.nav1 input:checked ~ span:nth-child(4) {
    background-color: transparent;
}


@media only screen and (max-width:950px) {
   .xx{
    margin-left: -22px !important ;
   }
}
.nav1 input:checked ~ .menu1 {
   
        width: 154px;
    
}


@media only screen and (max-width:800px) {
.fttt{
    display: none !important;
}
.nonee{
    display: none;
}
.navi{
    display: none !important;
}
.mobile-menu1{
    display: flex !important;
    justify-content: flex-end;
    margin-top: -55px;
}
.size-full{
    display: flex !important;
}
}

@media only screen and (max-width:890px) {

.full1{
    margin-right: 50px;
}

}

@media only screen and (max-width:450px) {

    .full1{
        flex-direction: column;
    }

}
.mobile-menu1{
    display: none;
}

@media only screen and (max-width:450px) {
.nav1 input {
    width: 37px;
    /* height: 40px; */
    cursor: pointer;
    opacity: 0;
}
.mobile-menu1{
    gap:7px;
}
}
@media only screen and (max-width:400px) {
.nav1 input {
    width: 32px;
    /* height: 40px; */
    cursor: pointer;
    opacity: 0;
}
.nav1 span {
    position: absolute;
    left: 30px;
    width: 22px;
    height: 4px;
    border-radius: 50px;
    background-color: white;
    pointer-events: none;
    transition: 0.5s;
}
}
@media only screen and (max-width:800px) {
    

    .mobile-menu1{
        display: flex !important;
        justify-content: flex-end;
        margin-top: -80px;
    }

}
@media only screen and (max-width:550px) {

.mobile-menu1 {
    display: flex !important;
    justify-content: flex-end;
    margin-top: -53px;
}
.elementor-image>img{
    width: 33% !important;
}
}
@media only screen and (max-width:404px) {

    .mobile-menu1 {
        display: flex !important;
        justify-content: flex-end;
        margin-top: -48px;
    }
    .righf{
        margin-right: 1px !important;
    }
    .menu1 li {
        list-style: none;
        margin: 0 7px;
        font-size: 12px;
    }
}